(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/wager/assets/javascripts/wager.js') >= 0) return;  svs.modules.push('/components/lb-utils/wager/assets/javascripts/wager.js');
"use strict";

var _svs$lbUtils$wager$da, _svs;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const logger = new svs.core.log.Logger('component:core.wager');
svs.lbUtils = svs.lbUtils || {};
svs.lbUtils.wager = svs.lbUtils.wager || {};
const data = svs.lbUtils.wager ? svs.lbUtils.wager.data : {};
const {
  trackGAWager
} = svs.utils;
const {
  roles
} = svs.core.userSession;
const {
  CurrencyFromJupiterString,
  Currency
} = svs.utils.format;
const {
  getCampaign
} = svs.components.lbUtils.campaignHandler;
const {
  CAMPAIGN_REWARD
} = svs.components.lbUtils.campaignHandler.constants;
const campaignForceFetchDelayMs = (_svs$lbUtils$wager$da = (_svs = svs) === null || _svs === void 0 || (_svs = _svs.lbUtils) === null || _svs === void 0 || (_svs = _svs.wager) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.campaignForceFetchDelayMs) !== null && _svs$lbUtils$wager$da !== void 0 ? _svs$lbUtils$wager$da : 200;
const {
  analytics
} = svs.components;
const {
  helpers
} = svs.utils.products;
const getCost = amount => {
  const nonBreakingAmountString = Currency(amount, false).split(' ').join('\xA0');
  return "".concat(nonBreakingAmountString, "\xA0kr");
};
const simpleProductName = {
  ANTMAL: 'Fullträff',
  MAL: 'Måltipset',
  STRYK: 'Stryktipset',
  TMIX: 'Challenge',
  ITA: 'Europatipset',
  T8STRYK: 'Topptipset',
  T8EXTRA: 'Topptipset',
  T8: 'Topptipset',
  POWERPLAY: 'Powerplay',
  BOMBEN: 'Bomben',
  MATCHEN: 'Matchen',
  RX: 'Hästar'
};
const productName = {
  ANTMAL: 'Fullträff',
  MAL: 'Måltipset',
  STRYK: 'Stryktipset',
  TMIX: 'Challenge',
  ITA: 'Europatipset',
  T8STRYK: 'Topptipset Stryktipset',
  T8EXTRA: 'Topptipset Extra',
  T8: 'Topptipset',
  POWERPLAY: 'Powerplay',
  BOMBEN: 'Bomben',
  MATCHEN: 'Matchen',
  SPORTJOKER: 'Sportkrysset',
  RX: 'Hästar'
};
const addonProductName = {
  STRYKANTMAL: 'Fullträff Stryktipset',
  T8ANTMAL: 'Fullträff Topptipset'
};
const trackingEvent = {
  category: 'overlays',
  action: null,
  opt_label: ''
};
const getProductName = (productId, wagersProps, index) => {
  if (wagersProps.length > 1 && index > 0) {
    if (productName[wagersProps[0].productId] === productName.STRYK) {
      return addonProductName.STRYKANTMAL;
    }
    if (productName[wagersProps[0].productId] === productName.T8STRYK) {
      return addonProductName.T8ANTMAL;
    }
    return undefined;
  }
  return productName[productId];
};
const trackEvent = _ref => {
  let {
    trackingAction
  } = _ref;
  const isMatchenOrBomben = helpers.isMatchen(trackingEvent.opt_label) || helpers.isBomben(trackingEvent.opt_label);
  if (!isMatchenOrBomben) {
    trackingEvent.action = trackingAction;
    analytics.trackEvent(trackingEvent);
  }
  trackingEvent.opt_label = '';
  trackingEvent.action = null;
};
const RACING_PRODUCT_ID = 'RX';
const wager = function () {
  var _this = this;
  const getPayPath = wagerprops => {
    if (wagerprops.marketplaceAccount) {
      return "/marketplace/1/activateddraws/".concat(wagerprops.marketplaceAccount.activatedDrawId, "/wagers");
    } else if (wagerprops.productId === RACING_PRODUCT_ID) {
      return '/racing/10/wager/wagers';
    }
    return '/wager/10/wagers';
  };
  const getCancelPath = (wagerId, productId) => {
    if (productId === RACING_PRODUCT_ID) {
      return "/racing/5/wager/wagers/".concat(wagerId);
    }
    return "/wager/4/wagers/".concat(wagerId);
  };
  const getMarketPlaceExtraData = betdata => {
    const extradata = {};
    if (betdata.betProperties && betdata.betProperties[0] === 'GROUP') {
      extradata.shares = true;
      extradata.typeOfShares = 'c2c';
    }
    return extradata;
  };
  const handleWagerRequest = (currentWager, currentWagerProps, currentTrackingObject, isLastWagerInMultipleWagers) => new Promise(resolve => {
    if (currentWagerProps.marketplaceAccount) {
      currentWagerProps.extradata = currentWagerProps.extradata || {};
      currentWagerProps.extradata.shares = true;
      switch (svs.core.session.data.userSession.role) {
        case roles.PLAYER:
          currentWagerProps.extradata.typeOfShares = 'c2c';
          break;
        default:
          currentWagerProps.extradata.typeOfShares = 'r2c';
      }
    }

    const payObject = {
      method: 'POST',
      path: getPayPath(currentWagerProps),
      data: currentWager
    };

    if (svs.lbUtils.wager.data && _.isEqual(currentWager, svs.lbUtils.wager.data.lastwager) && new Date().getTime() - svs.lbUtils.wager.data.lastwagertime.getTime() < 3000) {
      svs.events.publish('/loader/hide');
      resolve(false);
      return;
    }
    svs.lbUtils.wager.data = {
      lastwager: this.wager[0],
      lastwagertime: new Date()
    };
    let bet = {};
    let stryk;
    switch (currentWagerProps.productId) {
      case 'TMIX':
        _.each(currentWager.bets, bet => {
          currentTrackingObject.totalPrice += CurrencyFromJupiterString(bet.betAmountSalesGroup1);
        });
        bet = {
          productName: getProductName(currentWagerProps.productId, this.wagerprops, this.wagerprops.indexOf(currentWagerProps)),
          productId: currentWagerProps.productId,
          price: CurrencyFromJupiterString(currentWager.bets[0].betAmountSalesGroup1),
          numberOfRows: 1,
          extraData: currentWagerProps.extradata
        };
        trackingEvent.opt_label = bet.productName;
        currentTrackingObject.bets.push(bet);
        break;
      case 'MAL':
      case 'STRYK':
        {
          _.each(currentWager.bets, bet => {
            currentTrackingObject.totalPrice += CurrencyFromJupiterString(bet.betAmountSalesGroup1);
          });
          const strykdata = _.find(currentWager.bets, {
            productId: currentWagerProps.productId
          });
          stryk = {
            productName: getProductName(currentWagerProps.productId, this.wagerprops, this.wagerprops.indexOf(currentWagerProps)),
            productId: currentWagerProps.productId,
            price: CurrencyFromJupiterString(strykdata.betAmountSalesGroup1),
            numberOfRows: 1,
            extraData: currentWagerProps.extradata
          };
          currentTrackingObject.bets.push(stryk);
          trackingEvent.opt_label = stryk.productName;
          if (currentWager.bets.length > 1) {
            const sportkryssdata = _.find(currentWager.bets, bet => bet.productId !== currentWagerProps.productId);
            const sportkryss = {
              productName: productName[sportkryssdata.productId],
              productId: sportkryssdata.productId,
              price: CurrencyFromJupiterString(sportkryssdata.betAmountSalesGroup1),
              numberOfRows: 1,
              extraData: undefined
            };
            currentTrackingObject.bets.push(sportkryss);
          }
          break;
        }
      case 'ITA':
      case 'T8STRYK':
      case 'T8EXTRA':
      case 'T8':
      case 'POWERPLAY':
      case 'BOMBEN':
      case 'MATCHEN':
      case 'RX':
      case 'ANTMAL':
        bet = {
          productName: getProductName(currentWagerProps.productId, this.wagerprops, this.wagerprops.indexOf(currentWagerProps)),
          productId: currentWagerProps.productId,
          price: CurrencyFromJupiterString(currentWager.bets[0].betAmountSalesGroup1),
          numberOfRows: 1,
          extraData: currentWagerProps.extradata
        };
        trackingEvent.opt_label = simpleProductName[currentWagerProps.productId];
        currentTrackingObject.totalPrice = bet.price;
        currentTrackingObject.bets.push(bet);
        break;
      default:
    }

    let betAmount = CurrencyFromJupiterString(currentTrackingObject.totalPrice);
    if (currentWagerProps.betAmount) {
      betAmount = currentWagerProps.betAmount;
    }
    if (this.wager.length > 1) {
      betAmount = 0;
      this.wagerprops.map(wagerProp => betAmount += wagerProp.betAmount);
    }
    if (Object.prototype.hasOwnProperty.call(this, 'balance') && betAmount > this.balance && !currentWager.voucherId) {
      svs.events.publish('/loader/hide');
      this.abortCallback();
      showNoMoneyMessage.call(this, this.balance, betAmount);
      resolve(false);
    } else {
      svs.events.publish('/loader/hide');

      if (this.wager.length === 1 && currentWagerProps.message || currentWagerProps.message && this.wager.length > 1 && !isLastWagerInMultipleWagers) {
        const onPay = event => {
          logger.debug('Deactivating source element to prevent doubleclick on payment');
          event.srcElement.disabled = true;
          svs.components.dialog.api.close();
          if (currentWagerProps.competitionSignUp) {
            competitionSignUp(payObject, currentWagerProps, currentTrackingObject, isLastWagerInMultipleWagers, resolve);
          } else {
            var _payObject$data;
            if (payObject !== null && payObject !== void 0 && (_payObject$data = payObject.data) !== null && _payObject$data !== void 0 && _payObject$data.competitionId) {
              payObject.data.bets[0].betProperties = payObject.data.bets[0].betProperties || [];
              if (payObject.data.bets[0].betProperties.indexOf('COMPETITION') === -1) {
                payObject.data.bets[0].betProperties.push('COMPETITION');
              }
            }
            sendWager(payObject, currentWagerProps, currentTrackingObject, isLastWagerInMultipleWagers, resolve);
          }
        };
        const onAbort = () => {
          svs.components.dialog.api.close();
          togglePayButton(true);
          svs.lbUtils.wager.data = {};
          svs.events.publish('/payment/cancel');
          resolve(false);
          this.abortCallback();
        };
        const ValidationDialog = currentWagerProps.message.title ? svs.components.dialog.Confirm : svs.components.dialog.Payment;
        const dialogConfig = {
          branding: svs.components.dialog.branding.SPORT,
          icon: 'games',
          actions: [{
            title: 'Avbryt',
            callback: onAbort
          }, {
            title: 'Betala',
            callback: onPay,
            showLoader: true
          }]
        };
        if (this.wagerprops.length > 1) {
          const product = [];
          let price = 0;
          this.wagerprops.forEach(wagerprop => {
            product.push(wagerprop.message.product);
            price += wagerprop.message.price;
          });
          dialogConfig.price = price;
          dialogConfig.product = product;
          if (currentWagerProps.message.title) {
            dialogConfig.title = "Vill du betala dina spel p\xE5 ".concat(product[0], " och ").concat(product[1], " f\xF6r ").concat(getCost(price));
          }
        } else if (currentWagerProps.message.title) {
          dialogConfig.title = currentWagerProps.message.title;
        } else {
          dialogConfig.price = currentWagerProps.message.price;
          dialogConfig.product = currentWagerProps.message.product;
        }
        currentWagerProps.message.messages.forEach(message => {
          if (!dialogConfig.message) {
            dialogConfig.message = [];
          }
          dialogConfig.message.push({
            type: svs.components.dialog.message.TEXT,
            text: message,
            align: svs.components.dialog.message.align.CENTER
          });
        });
        togglePayButton(false);
        if (this.wager.length === 1 || this.wager.length > 1 && !isLastWagerInMultipleWagers) {
          svs.components.dialog.api.add(new ValidationDialog(dialogConfig));
        }
      } else {
        sendWager(payObject, currentWagerProps, currentTrackingObject, isLastWagerInMultipleWagers, resolve);
      }
    }
  });
  const makePayment = async accountbalance => {
    this.balance = CurrencyFromJupiterString(accountbalance);
    if (!this.wagerprops || this.wagerprops.some(wagerProp => !wagerProp.productId)) {
      logger.warn("No productId provided: ".concat(this.wagerprops));
      throw new Error('No productId provided');
    }
    if (this.wagerprops.some(wagerProp => wagerProp.marketplaceAccount && !wagerProp.marketplaceAccount.activatedDrawId)) {
      throw new Error('MarketplaceAccount provided without activatedDrawId');
    }
    for (let i = 0; i < this.wager.length; i++) {
      const currentWager = this.wager[i];
      const currentWagerProps = this.wagerprops[i];
      const currentTrackingObject = this.trackingWager[i];
      const isLastWagerInMultipleWagers = this.wager.length > 1 && i === this.wager.length - 1;
      let res;
      if (!currentWager.errorPay) {
        res = await handleWagerRequest(currentWager, currentWagerProps, currentTrackingObject, isLastWagerInMultipleWagers);
      }
      if (!res) {
        break;
      }
    }
    if (this.responses.length > 0) {
      this.successCallback(this.responses.length === 1 ? this.responses.pop() : this.responses);
    }
  };
  const alertCampaignWasUsed = () => {
    logger.info('alertCampaignWasUsed: Presenting campaign offer used in dialogue', CAMPAIGN_REWARD);
    svs.components.dialog.api.add(new svs.components.dialog.Confirm({
      branding: svs.components.dialog.branding.SPORT,
      icon: 'game-package',
      title: 'Ditt erbjudande är utnyttjat',
      message: [{
        type: svs.components.dialog.message.TEXT,
        text: "Utbetalning av ".concat(CAMPAIGN_REWARD, " kr kommer synas p\xE5 ditt spelkonto inom en vecka")
      }],
      actions: [{
        title: 'Okej'
      }]
    }));
  };
  const sendWager = (payObject, wagerProps, trackingWager, isLastWagerInMultipleWagers, onDone) => {
    svs.events.publish('/loader/show');
    svs.core.jupiter.call(payObject, async data => {
      togglePayButton(true);
      if (!data.wagerClientSerial) {
        data.wagerClientSerial = data.CreateWagerReply && data.CreateWagerReply.serial;
      }
      logger.info("success wagerid:".concat(data.wagerClientSerial, ", product:").concat(getProductName(wagerProps.productId, this.wagerprops, this.wagerprops.indexOf(wagerProps))));
      trackingWager.wagerId = data.wagerClientSerial;
      if (!wagerProps.noAccountbalanceUpdate) {
        svs.components.account_balance.set(data.accountBalance);
      }
      trackGAWager(trackingWager);
      if (svs.core.userSession.hasRole(roles.PLAYER) && !wagerProps.chunkData) {
        const campaignStatus = await getCampaign({
          onlyAvailability: false
        });
        if (campaignStatus.isCampaignAvailable && !campaignStatus.isCampaignUsed) {
          var _wagerProps$extradata;
          const wagerPropLogInfo = {
            productId: wagerProps.productId,
            price: wagerProps.message.price,
            rounds: (_wagerProps$extradata = wagerProps.extradata) === null || _wagerProps$extradata === void 0 ? void 0 : _wagerProps$extradata.rounds
          };
          logger.info('sendWager: Campaign available and has not been used', wagerPropLogInfo);
          setTimeout(async () => {
            const forceFetchedCampaignStatus = await getCampaign({
              forceFetch: true,
              onlyAvailability: false
            });
            logger.info("sendWager: Campaign forcefetched after ".concat(campaignForceFetchDelayMs, " ms delay."), forceFetchedCampaignStatus);
            if (forceFetchedCampaignStatus.isCampaignUsed) {
              alertCampaignWasUsed();
            }
          }, campaignForceFetchDelayMs);
        }
      }
      svs.events.publish('/loader/hide');
      this.responses.push(data);
      onDone(true);
    }, (jqXHR, textStatus, errorThrown) => {
      togglePayButton(true);
      logger.warn("failure (".concat(JSON.stringify(jqXHR), ", ").concat(textStatus, ", ").concat(errorThrown, ")"));
      const response = JSON.parse(jqXHR.responseText);
      svs.events.publish('/loader/hide');
      let errorcode;
      if (response.error && !response.error.code) {
        errorcode = 1;
        response.error.code = '1';
      } else if (typeof response.error.code === 'number') {
        errorcode = response.error.code;
      } else {
        errorcode = parseInt(response.error.code.replace(/\s/g, ''), 10);
      }
      if (this.overrideErrorHandling && this.errorCallback) {
        this.errorCallback(response);
        return onDone(false);
      }
      switch (errorcode) {
        case 28109:
          svs.components.dialog.api.add(new svs.components.dialog.Confirm({
            branding: svs.components.dialog.branding.SPORT,
            icon: 'time-limit',
            type: svs.components.dialog.type.CONFIRM,
            title: 'Du har nått din tidsgräns',
            message: [{
              type: svs.components.dialog.message.TEXT,
              text: 'När du nått din tidsgräns kan du inte betala för spel på Sport & Casino.'
            }],
            actions: [{
              title: 'Stäng',
              type: svs.components.dialog.button.SECONDARY
            }, {
              title: 'Till mina gränser',
              type: svs.components.dialog.button.PRIMARY,
              callback: () => {
                location.href = svs.core.urlMapping.get('myProfileLimits');
              }
            }]
          }));
          break;
        case 5117:
        case 6235:
          svs.sport.sport_common.showErrorDialog('Något gick tyvärr fel', 'Försök igen om en stund.');
          break;
        case 28105: 
        case 2814:
          showVerticalExclusionMessage();
          break;
        case 1019:
        case 5135:
          showSuspensionMessage();
          break;
        case 6149:
          svs.components.dialog.api.add(new svs.components.dialog.Confirm({
            branding: svs.components.dialog.branding.SPORT,
            icon: 'spelpaus',
            type: svs.components.dialog.type.ERROR,
            title: 'Ditt konto är spärrat',
            message: [{
              type: svs.components.dialog.message.TEXT,
              text: 'Kontakta kundservice för mer information.'
            }],
            actions: [{
              title: 'Kundservice',
              type: svs.components.dialog.button.SECONDARY,
              callback: () => {
                location.href = svs.core.urlMapping.get('customerServiceHome');
              }
            }, {
              title: 'Stäng',
              type: svs.components.dialog.button.SECONDARY
            }]
          }));
          break;
        case 6121:
          showNoMoneyMessage.call(this, this.balance);
          break;
        case 8222:
        case 8223:
        case 8225:
        case 8226:
        case 8228:
        case 8229:
          this.abortCallback();
          svs.components.dialog.api.add(new svs.components.dialog.Confirm({
            branding: svs.components.dialog.branding.SPORT,
            icon: 'my-limits',
            type: svs.components.dialog.type.ERROR,
            title: 'Du har överskridit en gräns',
            message: [{
              type: svs.components.dialog.message.TEXT,
              text: 'Högsta totala insats på omgången har överskridits.'
            }],
            actions: [{
              title: 'Okej'
            }]
          }));
          break;
        case 6332:
          this.abortCallback();
          showKycMessage();
          break;
        case 8224:
        case 8227:
        case 8230:
          svs.components.dialog.api.add(new svs.components.dialog.Confirm({
            branding: svs.components.dialog.branding.SPORT,
            icon: 'my-limits',
            type: svs.components.dialog.type.ERROR,
            title: 'Du har överskridit en gräns',
            message: [{
              type: svs.components.dialog.message.TEXT,
              text: 'Högsta totala dagliga insats på denna produkt har överskridits.'
            }],
            actions: [{
              title: 'Okej'
            }]
          }));
          break;
        case 8239:
        case 8240:
          svs.components.dialog.api.add(new svs.components.dialog.Confirm({
            branding: svs.components.dialog.branding.SPORT,
            icon: 'my-limits',
            type: svs.components.dialog.type.ERROR,
            title: 'Du har överskridit en gräns',
            message: [{
              type: svs.components.dialog.message.TEXT,
              text: 'Högsta antalet rader på denna omgång har överskridits.'
            }],
            actions: [{
              title: 'Okej'
            }]
          }));
          break;
        default:
          {
            if (this.wager.length > 1 && !isLastWagerInMultipleWagers || this.wager.length === 1) {
              this.errorCallback(response);
            }
          }
      }
      return onDone(false);
    });
  };
  const competitionSignUp = (payObject, wagerProps, trackingWager, isLastWagerInMultipleWagers, onDone) => {
    var _wagerProps$extraData, _wagerProps$extraData2;
    logger.info("Sign up to competition: ".concat(wagerProps.competitionSignUp.id));
    const compType = (_wagerProps$extraData = wagerProps === null || wagerProps === void 0 || (_wagerProps$extraData2 = wagerProps.extraData) === null || _wagerProps$extraData2 === void 0 ? void 0 : _wagerProps$extraData2.competition) !== null && _wagerProps$extraData !== void 0 ? _wagerProps$extraData : 'tävling';
    const compSignupData = {
      method: 'PUT',
      path: "/competition/2/competitions/".concat(wagerProps.competitionSignUp.id, "/participations"),
      data: {
        teamId: 0,
        signupType: 'PARTICIPATE',
        targetCustomerId: 0,
        scopeType: 'INDIVIDUAL_ONLY',
        invitationId: 0,
        locationId: 0
      }
    };
    svs.core.jupiter.call(compSignupData, response => {
      logger.debug("Anm\xE4lan till ".concat(compType, " gick bra"), response);
      payObject.data.competitionId = [wagerProps.competitionSignUp.id];
      payObject.data.bets[0].betProperties = payObject.data.bets[0].betProperties || [];
      if (payObject.data.bets[0].betProperties.indexOf('COMPETITION') === -1) {
        payObject.data.bets[0].betProperties.push('COMPETITION');
      }
      sendWager(payObject, wagerProps, trackingWager, isLastWagerInMultipleWagers, onDone);
    }, error => {
      logger.error("Anm\xE4lan till ".concat(compType, " gick inte bra"), error);
      svs.sport.sport_common.showErrorDialog('Något gick tyvärr fel', 'Försök igen om en stund.');
    });
  };
  const handleNotEnoughMoney = betAmount => {
    svs.events.publish('/loader/hide');
    showNoMoneyMessage(this.balance, betAmount);
  };
  const showErrorMessageBox = (message, title, options) => {
    options = options || {};
    const dialog = new svs.components.dialog.Confirm({
      icon: 'help-2',
      branding: svs.components.dialog.branding.SPORT,
      title: options.hideTitle ? '' : title,
      type: svs.components.dialog.type.ERROR,
      message: [{
        type: svs.components.dialog.message.TEXT,
        text: message
      }],
      actions: [{
        title: 'Okej'
      }]
    });
    svs.components.dialog.api.add(dialog);
  };
  const showVerticalExclusionMessage = async () => {
    const canPlay = await svs.accountservices.player_exclusions.canPlayOnVertical('tipsspel');
    canPlay && showErrorMessageBox('Prova igen om en stund.', 'Något gick tyvärr fel');
  };

  const showSuspensionMessage = extraInfo => {
    const options = {
      method: 'GET',
      path: '/player/1/suspensions'
    };
    const errorFn = () => {
      logger.error('Error fetching users suspensions');
      svs.components.dialog.api.add(new svs.components.dialog.Confirm({
        branding: svs.components.dialog.branding.SPORT,
        icon: 'spelpaus',
        title: 'Du har en aktiv spelpaus',
        message: [{
          type: svs.components.dialog.message.TEXT,
          text: "G\xE5 till Spelpaus f\xF6r att l\xE4sa mer."
        }],
        actions: [{
          title: 'Spelpaus',
          callback: () => {
            location.href = svs.core.urlMapping.get('gamingResponsibilityPause');
          }
        }, {
          title: 'Okej'
        }]
      }));
    };
    const successFn = response => {
      const activeSuspension = response.suspensions.filter(suspension => suspension.product === 'all' || suspension.product === 'quickGames');
      activeSuspension.forEach(suspension => {
        suspension.duration = dateFns.differenceInMonths(new Date(suspension.validTo || suspension.terminable), new Date(suspension.validFrom));
      });

      svs.components.dialog.api.add(new svs.components.dialog.Confirm({
        branding: svs.components.dialog.branding.SPORT,
        icon: 'spelpaus',
        message: [{
          type: svs.components.dialog.message.TEXT,
          text: svs.components.customer_login.templates.suspension_message({
            suspensions: activeSuspension,
            extraInfo: extraInfo ? extraInfo : ''
          })
        }],
        actions: [{
          title: 'Okej'
        }]
      }));
      this.abortCallback();
    };
    svs.core.jupiter.call(options, successFn, errorFn);
  };

  const showKycMessage = () => {
    svs.core.module.load('/cl/components/kyc-messages', null, () => {
      svs.components.kyc_messages.showKYCMessage();
    });
  };
  return {
    pay: (wager, wagerprops, successCallback, errorCallback, abortCallback, overrideErrorHandling, extraOptions) => {
      const wagers = [].concat(wager);
      const wagersprops = [].concat(wagerprops);
      const [productWagerProps] = wagersprops;
      if (typeof abortCallback !== 'function') {
        abortCallback = () => {};
      }
      logger.debug("paywager wager:".concat(JSON.stringify(wager)));
      logger.debug("paywager wagerprops:".concat(JSON.stringify(wagerprops)));
      this.trackingWager = [];
      for (const wagerprops of wagersprops) {
        this.trackingWager.push({
          productName: simpleProductName[wagerprops.productId],
          wagerId: 0,
          totalPrice: 0,
          bets: []
        });
      }
      this.responses = [];
      this.wager = wagers;
      this.wagerprops = wagersprops;
      this.successCallback = successCallback;
      this.errorCallback = errorCallback;
      this.abortCallback = abortCallback;
      this.balance = 0;
      this.overrideErrorHandling = overrideErrorHandling;
      this.extraOptions = extraOptions;
      togglePayButton(false);

      if (!svs.core.userSession.hasRole(roles.PLAYER) && !svs.core.userSession.hasRole(roles.RETAILER)) {
        svs.components.customer_login.login({
          returnNewCustomer: true
        });
      } else {
        svs.events.publish('/loader/show');
        if (productWagerProps && productWagerProps.marketplaceAccount) {
          _.defer(_.bind(makePayment, this, productWagerProps.marketplaceAccount.balance));
        } else if (svs.core.userSession.hasRole(roles.PLAYER)) {
          const forceRetrieveBalance = !wagerprops.chunkData || wagerprops.chunkData && wagerprops.chunkData.index === 0;
          svs.components.account_balance.get(forceRetrieveBalance, makePayment);
        } else {
          svs.components.customer_login.login({
            returnNewCustomer: true
          });
        }
      }
    },
    togglePayButton: on => {
      const payButton = $('.js-btn-pay');
      if (on && payButton.prop('disabled')) {
        payButton.prop('disabled', false);
      } else {
        payButton.prop('disabled', true);
      }
    },
    cancel: function (wagerid, messageConfig, successCallback, errorCallback, productId, wager) {
      let overrideOptions = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : {};
      if (typeof overrideOptions !== 'object') {
        throw new Error('Expected overrideOptions to be of type object');
      }
      try {
        const options = _objectSpread(_objectSpread({}, {
          path: getCancelPath(wagerid, productId)
        }), overrideOptions);

        if (!svs.core.userSession.hasRole(roles.PLAYER)) {
          svs.components.account_balance.on('change', accountbalance => {
            _this.balance = CurrencyFromJupiterString(accountbalance);
          });
          svs.components.customer_login.login();
        } else if (!isNaN(wagerid)) {
          const cancelObject = {
            method: 'DELETE',
            path: options.path
          };
          const trackingCancelWager = {
            wagerId: wagerid,
            totalPrice: 0,
            bets: []
          };
          if (messageConfig) {
            const validationDialogConfig = {
              branding: svs.components.dialog.branding.SPORT,
              icon: 'help',
              title: messageConfig.title,
              actions: [{
                title: 'Avbryt'
              }, {
                title: 'Annullera',
                callback: () => {
                  svs.components.dialog.api.close();
                  svs.events.publish('/loader/show');
                  svs.core.jupiter.call(cancelObject, async data => {
                    const returnData = data;
                    logger.info("cancelwager success wagerid:".concat(wagerid, ", productId").concat(productId));
                    let total = data && data.wagerAmounts && data.wagerAmounts.totalAmount;
                    if (!total) {
                      total = data && data.CancelWagerReply && data.CancelWagerReply.betAmount;
                      returnData.wagerAmounts = {
                        betAmount: total
                      };
                    }
                    total = CurrencyFromJupiterString(total) * -1;
                    if (wager) {
                      let extraData;
                      switch (productId) {
                        case 'MAL':
                        case 'STRYK':
                          {
                            const strykdata = _.find(wager.bets, {
                              productId
                            });
                            const stryk = {
                              productName: productName[productId],
                              productId: "".concat(productId, ".Annullering"),
                              price: CurrencyFromJupiterString(strykdata.betAmountSalesGroup1) * -1,
                              numberOfRows: 1
                            };
                            extraData = getMarketPlaceExtraData(strykdata);
                            if (extraData.shares) {
                              stryk.extraData = extraData;
                            }
                            trackingCancelWager.bets.push(stryk);
                            if (wager.bets.length > 1) {
                              const sportkryssdata = _.find(wager.bets, bet => bet.productId !== productId);
                              const sportkryss = {
                                productName: productName[sportkryssdata.productId],
                                productId: "".concat(sportkryssdata.productId, ".Annullering"),
                                price: CurrencyFromJupiterString(sportkryssdata.betAmountSalesGroup1) * -1,
                                numberOfRows: 1
                              };
                              trackingCancelWager.bets.push(sportkryss);
                            }
                            break;
                          }
                        default:
                          {
                            let betdata = _.where(wager.bets || wager.wager.bets || [], {
                              productId
                            });
                            betdata = betdata[0];
                            const betinfo = {
                              productName: productName[productId],
                              productId: "".concat(productId, ".Annullering"),
                              price: CurrencyFromJupiterString(betdata.betAmountSalesGroup1) * -1,
                              numberOfRows: 1
                            };
                            extraData = getMarketPlaceExtraData(betdata);
                            if (extraData.shares) {
                              betinfo.extraData = extraData;
                            }
                            trackingCancelWager.bets.push(betinfo);
                          }
                      }
                    } else {
                      let bet = {};
                      if (productId) {
                        bet = {
                          productName: productName[productId],
                          productId: "".concat(productId, ".Annullering"),
                          price: total,
                          numberOfRows: 1
                        };
                      } else {
                        bet = {
                          productName: 'Okänd',
                          productId: 'Okänd.Annullering',
                          price: total,
                          numberOfRows: 1
                        };
                      }
                      trackingCancelWager.bets.push(bet);
                    }
                    trackingCancelWager.totalPrice = total;
                    trackGAWager(trackingCancelWager);
                    if (svs.core.userSession.hasRole(roles.PLAYER)) {
                      const campaignStatus = await getCampaign({
                        onlyAvailability: false
                      });
                      if (campaignStatus.isCampaignUsed) {
                        logger.info('cancel: Campaign is used', campaignStatus, _this.wagerprops);
                        const forceFetchedCampaignStatus = await getCampaign({
                          forceFetch: true
                        });
                        logger.info('cancel: Campaign was forcefetched.', forceFetchedCampaignStatus);
                      }
                    }
                    svs.events.publish('/loader/hide');
                    svs.components.account_balance.get(true);
                    successCallback(returnData);
                  }, (jqXHR, textStatus, errorThrown) => {
                    logger.warn("cancelwager failure (".concat(JSON.stringify(jqXHR), ", ").concat(textStatus, ", ").concat(errorThrown, ")"));
                    const response = JSON.parse(jqXHR.responseText);
                    svs.events.publish('/loader/hide');
                    errorCallback(response);
                  });
                }
              }]
            };
            if (messageConfig.message) {
              validationDialogConfig.message = [{
                type: svs.components.dialog.message.TEXT,
                text: messageConfig
              }];
            }
            svs.components.dialog.api.add(new svs.components.dialog.Confirm(validationDialogConfig));
          }
        }
      } catch (e) {
        logger.warn("cancelwager failure (".concat(e, ")"));
        const response = {
          error: {
            conde: 0
          }
        };
        svs.events.publish('/loader/hide');
        errorCallback(response);
      }
    },
    handleNotEnoughMoney,
    showSuspensionMessage,
    isPaymentOngoing: () => svs.components.Storage.browser.get('wagerstart', 'Wager'),
    showKycMessage,
    abortWager
  };
}();
function abortWager(abortWagerCallback) {
  trackEvent({
    trackingAction: 'cancel deposit'
  });
  svs.components.Storage.browser.remove('wagerstart', 'Wager', () => {
    svs.events.publish('/wager/paymentAborted');
    togglePayButton(true);
    if (abortWagerCallback && _.isFunction(abortWagerCallback)) {
      abortWagerCallback();
    }
  });
}
function showTransfer() {
  var _this$extraOptions;
  trackEvent({
    trackingAction: 'deposit'
  });
  svs.components.Storage.browser.remove('wagerstart', 'Wager', () => {});
  const url = new URL(window.location);
  if (((_this$extraOptions = this.extraOptions) === null || _this$extraOptions === void 0 || (_this$extraOptions = _this$extraOptions.url) === null || _this$extraOptions === void 0 || (_this$extraOptions = _this$extraOptions.params) === null || _this$extraOptions === void 0 ? void 0 : _this$extraOptions.length) > 0) {
    this.extraOptions.url.params.forEach(_ref2 => {
      let [key, value] = _ref2;
      url.searchParams.append(key, value);
    });
  }
  svs.utils.form.postToUrl(svs.core.urlMapping.get('transactionsHome'), {
    returnUrl: url
  });
}
function togglePayButton(on) {
  const payButton = $('.js-btn-pay');
  if (on && payButton.is(':disabled')) {
    payButton.attr('disabled', false);
  } else if (!on && payButton.is(':enabled')) {
    payButton.attr('disabled', true);
  }
}
function showNoMoneyMessage(balance, betAmount) {
  const isMarketplace = this && this.wagerprops && this.wagerprops[0] && this.wagerprops[0].marketplaceAccount;
  const hasBalance = balance !== undefined;
  const hasCost = betAmount;
  const cost = hasCost ? svs.utils.format.Currency(betAmount) : null;
  const gamesStr = this.wager.length > 1 ? 'dina spel.' : 'ditt spel.';
  const title = isMarketplace ? 'Ni har inte tillräckligt mycket pengar för att betala det här spelet' : "Du har inte tillr\xE4ckligt med pengar p\xE5 ditt spelkonto f\xF6r att betala ".concat(gamesStr);
  const messages = [];
  balance = hasBalance ? svs.utils.format.Currency(balance) : null;
  if (hasBalance) {
    if (isMarketplace) {
      messages.push("Att spela f\xF6r: ".concat(balance, " kr"));
    } else {
      messages.push("Saldo: ".concat(balance, " kr"));
    }
  }
  if (hasCost) {
    messages.push("Pris: ".concat(cost, " kr"));
  }
  const dialogConfig = {
    branding: svs.components.dialog.branding.SPORT,
    icon: 'balance',
    title,
    actions: []
  };
  if (messages.length) {
    dialogConfig.message = [{
      type: svs.components.dialog.message.TEXT,
      text: messages.join('<br />')
    }];
  }
  if (isMarketplace) {
    dialogConfig.actions.push({
      title: 'Okej',
      callback: () => {
        svs.components.dialog.api.close();
        abortWager();
      }
    });
  } else {
    dialogConfig.actions.push({
      title: 'Avbryt',
      callback: () => {
        svs.components.dialog.api.close();
        abortWager();
      }
    });
    dialogConfig.actions.push({
      title: 'Sätt in',
      callback: () => {
        svs.components.dialog.api.close();
        showTransfer();
      }
    });
  }
  svs.components.dialog.api.add(new svs.components.dialog.Confirm(dialogConfig));
}
wager.data = data;
svs.lbUtils.wager = wager;

 })(window);