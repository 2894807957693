(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/wager/assets/javascripts/error-codes.js') >= 0) return;  svs.modules.push('/components/lb-utils/wager/assets/javascripts/error-codes.js');
"use strict";

const ErrorCodes = {
  BAD_OPENHOURS: 8101,
  BAD_BET: 8103,
  BAD_DRAWSTATE: 8105,
  DRAW_NOT_OPENED: 8107,
  INCORRECT_BETFILE: 8111,
  NO_BETFILE: 8112,
  CANCELBET_DISABLED: 8122,
  REGBET_DISABLED: 8124,
  INVALID_RETAILERID: 8125,
  CUST_MAX_DRAW_REGAMT: 8222,
  CUST_MAX_DRAW_EGNARADER_REGAMT: 8223,
  SYNDICATE_MAX_DRAW_REGAMT: 8225,
  SYNDICATE_MAX_DRAW_EGNARADER_REGAMT: 8226,
  RETAILER_MAX_DRAW_REGAMT: 8228,
  RETAILER_MAX_DRAW_ANDELSSPEL_REGAMT: 8229,
  CUST_MAX_BOARDS_IN_DRAW: 8239,
  SYNDICATE_MAX_BOARDS_IN_DRAW: 8240,
  ACTIVATED_DRAW_CLOSED: 41,
  NOT_ENOUGH_MONEY: 28106
};
setGlobal('svs.lbUtils.ErrorCodes', ErrorCodes);

 })(window);