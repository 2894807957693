(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/wager/assets/javascripts/create-wager-object.js') >= 0) return;  svs.modules.push('/components/lb-utils/wager/assets/javascripts/create-wager-object.js');
"use strict";


const {
  CurrencyAsJupiterString: currencyAsJupiterString
} = svs.utils.format;
const {
  SYSTEMS
} = svs.components.sport.systems;
const MSYS = 'MSYS';
const USYS = 'USYS';
const RSYS = 'RSYS';
function getSystemType(system) {
  const firstChar = system[0].toLowerCase();
  if (firstChar === 'm') {
    return MSYS;
  }
  if (firstChar === 'r') {
    return RSYS;
  }
  if (firstChar === 'u') {
    return USYS;
  }
  return system;
}
function getSizeFromRow(row, system, systemType) {
  if (systemType === RSYS || systemType === USYS) {
    const [,, size] = system.split('-');
    return Number(size);
  }
  let size = 1;
  for (let i = 0; i < row.length; ++i) {
    size *= row[i].length;
  }
  return size;
}
function getSystemNum(system, systemType) {
  if (systemType === MSYS) {
    return 1;
  }
  if (systemType === RSYS || systemType === USYS) {
    return SYSTEMS[system].systemNum;
  }
}
function createWagerObject(_ref) {
  let {
    productId,
    drawNumber,
    cost,
    row,
    isCompetition,
    isXperten,
    system
  } = _ref;
  const betProperties = [];
  if (isCompetition) {
    betProperties.push('COMPETITION');
  }
  const systemType = getSystemType(system);
  const wager = {
    crc: 0,
    marketInfo: 0,
    bagType: 0,
    bets: [{
      productId,
      drawNum: drawNumber,
      betAmountSalesGroup1: currencyAsJupiterString(cost),
      betAmountSalesGroup2: '0,00',
      game1X2Boards: [{
        boardData: row,
        boardProps: [isXperten ? 'WEIGHTEDQP' : null].filter(Boolean),
        numSelections: getSizeFromRow(row, system, systemType),
        rowPriceMulti: 1,
        systemNum: getSystemNum(system, systemType),
        systemType: [systemType]
      }],
      rowPriceMulti: 1,
      numDraws: 1,
      betProperties
    }]
  };
  return wager;
}
setGlobal('svs.lbUtils.wager.createWagerObject', createWagerObject);

 })(window);